<template>
  <div>
    <section
      v-if="!isDataDaily"
      class="bg-white invoice p-6 rounded-[8px] flex flex-col gap-[24px]"
    >
      <div class="flex gap-[24px] items-center">
        <span class="font-semibold text-[20px] text-[#333]">Talent</span>
        <div class="month-picker w-[140px] ms-auto border flex justify-between rounded items-center px-[5px] py-[5px]">
          <MonthlyPicker
            v-model="filterDate"
            :month-labels="monthlabel"
            date-format="MMM YYYY"
            style="width: 100px"
            input-class="border-0 w-[100px] h-full font-semibold"
            :clear-option="false"
            :alignment="'center'"
            :selected-background-color="'#F95031'"
            :max="moment()"
            @selected="getTalent(), getTotalTalent()"
          />
          <span class="k-calendar text-3xl" />
        </div>
        <span class="flex items-center font-semibold">Data Bulanan</span>
        <div class="navigate flex">
          <button
            class="flex justify-center items-center p-[8px] border rounded-left corsor-pointer bg-[#F4F4F4]"
            disabled
          >
            <span class="k-arrow-left-2" />
          </button>
          <button
            class="flex justify-center items-center p-[8px] border rounded-right corsor-pointer"
            @click="isDataDaily = true"
          >
            <span class="k-arrow-right-2 text-[#333]" />
          </button>
        </div>
      </div>
      <div
        v-if="Object.keys(dataTalent).length"
        class="flex flex-wrap flex-md-nowrap gap-[16px]"
      >
        <template v-for="(value, index) in dataTalent">
          <div
            v-if="value"
            :key="index"
            class="border rounded-[8px] flex flex-col w-full md:w-1/2 lg:w-1/4"
          >
            <div class="card-head flex flex-col gap-[12px] p-[12px]">
              <span class="text-[#333] font-medium text-base">
                {{ value.name }}
              </span>
              <div class="flex flex-col">
                <span class="text-[#828282] text-[10px]">Talent Aktif</span>
                <span class="text-[#333] font-bold text-[20px]">{{ value.count || 0 }}</span>
              </div>
            </div>
            <div class="bg-[#F4F4F4] flex flex-col px-[10px] py-[12px] gap-[8px]">
              <div class="text-[#333] text-base flex items-center">
                <span class="w-[8px] h-[8px] bg-[#FBA63C] rounded-full mr-[5px]" /><span class="w-1/2"> New Hired </span> <strong>: {{ value.new_partner }}</strong> <small class="ms-auto text-[#34A770]">{{ value.new_partner_day ? `+${value.new_partner_day} Hari ini` : '' }}</small>
              </div>
              <div class="text-[#333] text-base flex items-center">
                <span class="w-[8px] h-[8px] bg-[#34A770] rounded-full mr-[5px]" /><span class="w-1/2"> Penambahan </span> <strong>: {{ value.new_hire }}</strong> <small class="ms-auto text-[#34A770]">{{ value.new_hire_day ? `+${value.new_hire_day} Hari ini` : '' }}</small>
              </div>
              <div class="text-[#333] text-base flex items-center">
                <span class="w-[8px] h-[8px] bg-[#E31A1A] rounded-full mr-[5px]" /><span class="w-1/2"> Pengurangan </span> <strong>: {{ value.resign }}</strong> <small class="ms-auto text-[#E31A1A]">{{ value.resign_day ? `+${value.resign_day} Hari ini` : '' }}</small>
              </div>
              <div class="text-[#333] text-base flex items-center">
                <span class="w-1/2 mr-[13px]"> Multiple </span> <strong>: {{ value.multiple || 0 }}</strong>
              </div>
              <div class="text-[#333] text-base flex items-center">
                <span class="w-1/2 mr-[13px]"> Non Job </span> <strong>: {{ value.non_job || 0 }}</strong>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="flex gap-[24px] flex-wrap flex-md-nowrap md:flex-nowrap">
        <Card
          :container-class="'w-full md:w-1/3 !p-[12px]'"
          :title="'Empowerment'"
          :append-title-icon="'k-info-circle-1 text-2xl text-[#08A0F7]'"
          :content="`${dataTotal.empowerment || 0}`"
          :content-class="'py-[10px] text-[14px]'"
          :tooltip-text="'Total Talent Hired + Multiple pada semua layanan'"
        />
        <Card
          :container-class="'w-full md:w-1/3 !p-[12px]'"
          :title="'Total Non Job'"
          :content="`${dataTotal.non_job || 0}`"
          :content-class="'py-[10px] text-[14px]'"
        />
        <Card
          :container-class="'w-full md:w-1/3 !p-[12px]'"
          :title="'Total Resign'"
          :content="`${dataTotal.resign || 0}`"
          :content-class="'py-[10px] text-[14px]'"
        />
      </div>
    </section>
    <section
      v-else
      class="bg-white invoice p-6 rounded-[8px] flex flex-col gap-[24px]"
    >
      <div class="flex gap-[24px] items-center">
        <span class="font-semibold text-[20px] text-[#333]">Talent</span>
        <div class="ms-auto selection">
          <VSelect
            v-model="filterType"
            :reduce="e => e.value"
            :options="optionsType"
            label="text"
            :clearable="false"
            class="font-semibold"
            style="width: 160px;"
          />
        </div>
        <div class="month-picker w-[140px] border flex justify-between rounded items-center px-[5px] py-[5px]">
          <MonthlyPicker
            v-model="filterDateTalentDetail"
            :month-labels="monthlabel"
            date-format="MMM YYYY"
            style="width: 100px"
            input-class="border-0 w-[100px] h-full font-semibold"
            :clear-option="false"
            :alignment="'center'"
            :selected-background-color="'#F95031'"
            :max="moment()"
            @selected="getTalentDetail()"
          />
          <span class="k-calendar text-3xl" />
        </div>
        <span class="flex items-center font-semibold">Data Harian</span>
        <div class="navigate flex">
          <button
            class="flex justify-center items-center p-[8px] border rounded-left corsor-pointer"
            @click="isDataDaily = false"
          >
            <span class="k-arrow-left-2 text-[#333] font-semibold" />
          </button>
          <button
            class="flex justify-center items-center p-[8px] border rounded-right corsor-pointer bg-[#F4F4F4]"
            disabled
          >
            <span class="k-arrow-right-2" />
          </button>
        </div>
      </div>
      <Chart
        :loading="loading"
        :chart-of="'talent'"
        :series="series"
      />
    </section>
  </div>
</template>

<script>
import { IDR } from '@/libs/currency'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import moment from 'moment'
import MonthlyPicker from 'vue-monthly-picker'
import VSelect from 'vue-select'
import Chart from './Chart.vue'
import Card from './CardTotal.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    Chart,
    Card,
    MonthlyPicker,
    VSelect,
  },
  data() {
    return {
      IDR,
      alertError,
      filterDate: moment(),
      filterDateTalentDetail: moment(),
      monthlabel: LABELMONTH,
      moment,
      dataTotal: {},
      dataTalent: [],
      isDataDaily: false,
      dataSeries: [],
      filterType: 'new_partner',
      optionsType: [
        {
          text: 'New Hired',
          value: 'new_partner',
        },
        {
          text: 'Resign',
          value: 'resign',
        },
        {
          text: 'Penambahan',
          value: 'new_hire',
        },
      ],
    }
  },
  computed: {
    series() {
      let result = []
      result = [
        {
          name: 'Customer Service',
          data: this.dataSeries.filter(el => el.name === 'Customer Service').map(el => el[this.filterType]),
          color: '#E31A1A',
        },
        {
          name: 'Live Streamer',
          data: this.dataSeries.filter(el => el.name === 'Live Streamer').map(el => el[this.filterType]),
          color: '#FBBC05',
        },
        {
          name: 'Advertiser',
          data: this.dataSeries.filter(el => el.name === 'Advertiser').map(el => el[this.filterType]),
          color: '#34A770',
        },
        {
          name: 'Admin Marketplace',
          data: this.dataSeries.filter(el => el.name === 'Admin Marketplace').map(el => el[this.filterType]),
          color: '#08A0F7',
        },
      ]
      return result
    },
  },
  mounted() {
    this.getTalent()
    this.getTotalTalent()
    this.getTalentDetail()
  },
  methods: {
    async getTalent() {
      this.loading = true
      const params = `date=${YEAR_MONTH(this.filterDate)}`
      const url = `/v1/dashboard/talent?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          const role = ['Admin Marketplace', 'Advertiser', 'Customer Service', 'Live Streamer']
          this.dataTalent = data.filter(el => role.includes(el.name))
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getTalentDetail() {
      this.loading = true
      const params = `date=${YEAR_MONTH(this.filterDateTalentDetail)}`
      const url = `/v1/dashboard/talent-detail?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.dataSeries = data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getTotalTalent() {
      this.loading = true
      const params = `date=${YEAR_MONTH(this.filterDate)}`
      const url = `/v1/dashboard/talent-total?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.dataTotal = data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
  },
}
</script>

<style>
.selection .vs__dropdown-toggle {
  height: 40px
}
.selection .vs__selected-options {
  text-wrap: nowrap;
}
.month-picker .month-year-display.input{
  width: 120px;
}
</style>
