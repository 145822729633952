<template>
  <section class="bg-white invoice p-6 rounded-[8px] flex flex-col gap-[24px]">
    <div class="flex">
      <span class="font-semibold text-[20px] text-[#333]">Partner</span>
    </div>
    <div class="flex flex-col flex-md-row gap-[24px]">
      <div class="flex flex-col rounded-lg border p-[12px] w-4/6">
        <div class="flex mb-[24px]">
          <span class="font-semibold text-lg text-[#333]">Kategori Partner</span>
          <div class="ms-auto selection">
            <VSelect
              v-model="filterCategory"
              :reduce="e => e.value"
              :options="optionsFilter"
              label="text"
              :clearable="false"
              class="font-semibold"
              style="width: 100px;"
              @input="getPartnerCategory()"
            />
          </div>
        </div>
        <b-overlay
          :show="loadingPartnerCategory"
          spinner-variant="primary"
        >
          <div class="grid grid-cols-2 gap-[16px]">
            <CardData
              :table-head="'Gender'"
              :data="dataGender"
            />
            <CardData
              :table-head="'Tipe Pebisnis'"
              :data="dataBusinessType"
              :class-name="'h-[180px]'"
            />
            <CardData
              :table-head="'Kategori Bisnis'"
              :data="dataBusinessCategory"
            />
            <CardData
              :table-head="'Kota'"
              :data="dataCity"
            />
          </div>
        </b-overlay>
      </div>
      <div class="flex flex-col w-2/6 gap-[12px]">
        <div class="border rounded-lg">
          <div class="flex p-1 items-center">
            <span class="text-lg font-semibold text-[#333]">Status Partner</span>
            <div class="month-picker w-[130px] ms-auto border flex justify-between rounded items-center px-[5px] py-[5px]">
              <MonthlyPicker
                v-model="periode"
                :month-labels="monthlabel"
                date-format="MMM YYYY"
                style="width: 100px"
                input-class="border-0 w-[100px] h-full font-semibold"
                :clear-option="false"
                :alignment="'center'"
                :selected-background-color="'#F95031'"
                :max="moment()"
                @selected="getPartnerStatus()"
              />
              <span class="k-calendar text-3xl" />
            </div>
          </div>
          <div v-if="!loadingStatusPartner">
            <div
              class="flex flex-col w-full"
            >
              <div class="card-head border-y flex flex-col gap-[12px] p-[12px]">
                <span class="text-[#333] font-medium text-base">
                  Partner Aktif
                </span>
                <div class="flex items-center">
                  <span
                    v-if="dataPartnerStatus.current"
                    class="text-[#34A770] text-[20px] font-semibold"
                  >{{ extractData(dataPartnerStatus.current, 'active') }}</span>
                  <span
                    v-if="extractData(dataPartnerStatus.additional, 'active')"
                    class="text-[#34A770] text-sm ml-[5px]"
                  >+{{ extractData(dataPartnerStatus.additional, 'active') }} Hari Ini</span>
                </div>
              </div>
              <div class="bg-[#F4F4F4] flex flex-col px-[10px] py-[12px] gap-[8px]">
                <div
                  v-for="(total, name) in dataPartnerStatus.detail"
                  :key="name"
                  class="text-[#333] text-base flex items-center"
                >
                  <span
                    class="w-[8px] h-[8px] rounded-full mr-[5px]"
                    :class="getRoleClass(name)"
                  /><span class="w-1/2">{{ name }}</span> <strong class="ml-auto">{{ total }}</strong>
                </div>
              </div>
              <div class="card-head flex flex-col gap-[12px] p-[12px]">
                <span class="text-[#333] font-medium text-base">
                  Partner Off
                </span>
                <div class="flex items-center">
                  <span
                    v-if="dataPartnerStatus.current"
                    class="text-[#E31A1A] text-[20px] font-semibold"
                  >{{ extractData(dataPartnerStatus.current, 'off') }}</span>
                  <span
                    v-if="extractData(dataPartnerStatus.additional, 'off')"
                    class="text-[#E31A1A] text-sm ml-[5px]"
                  >+{{ extractData(dataPartnerStatus.additional, 'off') }} Hari Ini</span>
                </div>
              </div>
            </div>
          </div>
          <div class="min-w-[50px]">
            <b-overlay
              :show="loadingStatusPartner"
              spinner-variant="primary"
            />
          </div>
        </div>

        <div class="border rounded-lg">
          <div class="flex p-1 items-center">
            <span class="text-base font-medium text-[#333]">Customer Lifetime Value</span>
            <span
              v-b-tooltip.hover.top="'CLV adalah rata-rata durasi partner menunggunakan layanan Komtim'"
              class="k-info-circle-1 text-2xl text-[#08A0F7] ml-[5px]"
            />
            <div class="ms-auto selection">
              <VSelect
                v-model="filterLifetime"
                :reduce="e => e.value"
                :options="optionsFilter"
                label="text"
                :clearable="false"
                class="font-semibold"
                style="width: 100px;"
                @input="getPartnerCustomerLifetime()"
              />
            </div>
          </div>
          <b-overlay
            :show="loadingCustomerLifetime"
            spinner-variant="primary"
          >
            <div class="bg-[#F4F4F4] flex flex-col px-[10px] py-[12px] gap-[8px]">
              <div
                v-for="(total, name) in dataLifetime"
                :key="name"
                class="text-[#333] text-base flex items-center"
              >
                <span
                  class="w-[8px] h-[8px] rounded-full mr-[5px]"
                  :class="getRoleClass(name)"
                /><span class="w-1/2"> {{ name }} </span> <strong class="ml-auto">{{ convertMonthsToYearsAndMonths(total) }}</strong>
              </div>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import VSelect from 'vue-select'
import moment from 'moment'
import { alertError, alertSuccess } from '@toast'
import MonthlyPicker from 'vue-monthly-picker'
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import CardData from './CardData.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    CardData,
    VSelect,
    MonthlyPicker,
  },
  data() {
    return {
      alertError,
      isAllTime: false,
      dataGender: [],
      dataBusinessType: [],
      dataBusinessCategory: [],
      dataCity: [],
      dataLifetime: [],
      dataStatus: [],
      filterLifetime: false,
      filterCategory: false,
      optionsFilter: [
        {
          text: 'All Time',
          value: true,
        },
        {
          text: 'Aktif',
          value: false,
        },
      ],
      monthlabel: LABELMONTH,
      periode: moment(),
      dataPartnerStatus: {},
      loadingPartnerCategory: true,
      loadingStatusPartner: true,
      loadingCustomerLifetime: true,
      moment,
    }
  },
  mounted() {
    this.getPartnerCategory()
    this.getPartnerCustomerLifetime()
    this.getPartnerStatus()
  },
  methods: {
    async getPartnerCategory() {
      this.loadingPartnerCategory = true
      const params = `all_time=${this.filterCategory}`
      const url = `/v1/dashboard/partner-category?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.dataGender = data.find(e => e.type === 'gender')?.data.sort((a, b) => b.total - a.total)
          this.dataBusinessCategory = data.find(e => e.type === 'business_category')?.data.sort((a, b) => b.total - a.total)
          this.dataBusinessType = data.find(e => e.type === 'business_type')?.data.sort((a, b) => b.total - a.total)
          this.dataCity = data.find(e => e.type === 'city')?.data.sort((a, b) => b.total - a.total)
          this.loadingPartnerCategory = false
        })
        .catch(err => {
          this.loadingPartnerCategory = false
          this.alertError(err)
        })
    },
    async getPartnerCustomerLifetime() {
      this.loadingCustomerLifetime = true
      const params = `all_time=${this.filterLifetime}`
      const url = `/v1/dashboard/customer-lifetime?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          const role = ['Admin Marketplace', 'Advertiser', 'Customer Service', 'Live Streamer']
          this.dataLifetime = data.filter(el => role.includes(el.name)).reduce((acc, item) => {
            acc[item.name] = item.total
            return acc
          }, {})
          this.loadingCustomerLifetime = false
        })
        .catch(err => {
          this.loadingCustomerLifetime = false
          this.alertError(err)
        })
    },
    async getPartnerStatus() {
      this.loadingStatusPartner = true
      const params = `date=${YEAR_MONTH(this.periode)}`
      const url = `/v1/dashboard/partner-status?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          const role = ['Admin Marketplace', 'Advertiser', 'Customer Service', 'Live Streamer']

          this.dataPartnerStatus.detail = data.find(e => e.type === 'detail')?.data.filter(el => role.includes(el.name)).reduce((acc, item) => {
            acc[item.name] = item.total
            return acc
          }, {})
          this.dataPartnerStatus.additional = data.find(e => e.type === 'status')?.data
          this.dataPartnerStatus.current = data.find(e => e.type === 'partner')?.data
          this.loadingStatusPartner = false
        })
        .catch(err => {
          this.loadingStatusPartner = false
          this.alertError(err)
        })
    },
    getRoleClass(name) {
      switch (name) {
        case 'Admin Marketplace':
          return 'bg-[#08A0F7]'
        case 'Advertiser':
          return 'bg-[#34A770]'
        case 'Customer Service':
          return 'bg-[#E31A1A]'
        case 'Live Streamer':
          return 'bg-[#FBA63C]'
        default:
          return ''
      }
    },
    convertMonthsToYearsAndMonths(months) {
      if (months < 12) {
        return `${months} bulan`
      }
      const years = Math.floor(months / 12)
      const remainingMonths = months % 12
      if (remainingMonths === 0) {
        return `${years} tahun`
      }
      return `${years} Tahun ${remainingMonths} Bulan`
    },
    extractData(arr, type) {
      if (arr) {
        if (arr.length > 0 && type === 'active') {
          const result = arr.find(e => e.name === 'active')
          return result ? result.total : 0
        }
        if (arr.length > 0 && type === 'off') {
          const result = arr.find(e => e.name === 'off')
          return result ? result.total : 0
        }
      }

      return 0
    },
  },
}
</script>

<style>
.selection .vs__dropdown-toggle {
  height: 40px
}
.selection .vs__selected-options {
  text-wrap: nowrap;
}
.month-picker .month-year-display.input{
  width: 120px;
}
</style>
