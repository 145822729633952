<template>
  <section class="bg-white invoice p-6 rounded-[8px]">
    <div class="flex">
      <span class="font-semibold text-[20px] text-[#333]">Invoice</span>
      <div class="filter ms-auto flex gap-[10px]">
        <VSelect
          v-model="filterIsPaid"
          :reduce="e => e.value"
          :options="optionsIsPaid"
          label="text"
          :clearable="false"
          style="width: 120px;"
          class="font-semibold !text-[#333]"
          @input="getChartInvoice()"
        />
        <VSelect
          v-model="filterType"
          :reduce="e => e.value"
          :options="optionsType"
          label="text"
          :clearable="false"
          style="width: 160px;"
          class="font-semibold !text-[#333]"
          @input="getChartInvoice()"
        />
        <YearPicker
          v-model="filterYear"
          :format="START_YEAR_NOW"
          minimum-view="year"
          name="datepicker"
          wrapper-class="border-solid border rounded cursor-pointer flex items-center"
          calendar-class="text-center ml-[-22em]"
          input-class="text-left pl-[20px] w-[100px] font-semibold"
          :calendar-button="true"
          :calendar-button-icon="'fa-calendar'"
          @input="getChartInvoice()"
        >
          <span
            slot="afterDateInput"
            class="text-lg absolute right-[10px] pointer-events-none bottom-[1px]"
          >
            <span class="k-calendar text-3xl" />
          </span>
        </YearPicker>
      </div>
    </div>
    <Chart
      :series="series"
      :loading="loading"
    />
    <div class="my-[24px] flex justify-end">
      <div class="month-picker w-[130px] border flex justify-between rounded items-center px-[5px] py-[5px]">
        <MonthlyPicker
          v-model="filterMonth"
          :month-labels="monthlabel"
          date-format="MMM YYYY"
          style="width: 90px;"
          input-class="border-0 w-[100px] h-full font-semibold"
          :clear-option="false"
          :alignment="'center'"
          :selected-background-color="'#F95031'"
          :max="moment()"
          @selected="getTotalInvoice()"
        />
        <span class="k-calendar text-3xl" />
      </div>
    </div>
    <div class="flex gap-[24px] mt-[24px] flex-wrap flex-md-nowrap">
      <Card
        :container-class="'w-full md:w-1/2'"
        :title="'Total Biaya Admin Paid'"
        :prepend-title-icon="'k-card-tick-1'"
        :content="`${IDR(dataTotal.admin_fee)},-`"
        :content-class="'py-[10px]'"
      >
        <template v-slot:footer>
          <div class="flex flex-col">
            <span class="text-[#34A770] text-[12px]">Total partner ditagihkan : {{ dataTotal.total_partner }}</span>
            <span class="text-[#08A0F7] text-[12px]">Total talent yang ditagih invoice : {{ dataTotal.total_talent }}</span>
          </div>
        </template>
      </Card>
      <Card
        :container-class="'w-full md:w-1/2'"
        :title="'Total Sharing Fee'"
        :prepend-title-icon="'k-convert-card'"
        :content="`${IDR(dataTotal.sharing_fee)},-`"
        :content-class="'py-[10px]'"
      />
    </div>
  </section>
</template>

<script>
import { IDR } from '@/libs/currency'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import MonthlyPicker from 'vue-monthly-picker'
import moment from 'moment'
import {
  LABELMONTH, START_YEAR_NOW, YEAR, YEAR_MONTH_DAY,
} from '@/libs/filterDate'
import YearPicker from 'vuejs-datepicker'
import VSelect from 'vue-select'
import Chart from './Chart.vue'
import Card from './CardTotal.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    Chart,
    Card,
    VSelect,
    YearPicker,
    MonthlyPicker,
  },
  data() {
    return {
      IDR,
      alertError,
      loading: false,
      series: [],
      dataTotal: {},
      monthlabel: LABELMONTH,
      filterIsPaid: 'paid',
      filterMonth: moment(),
      optionsIsPaid: [
        {
          text: 'Paid',
          value: 'paid',
        },
        {
          text: 'Publish',
          value: 'publish',
        },
      ],
      filterType: 'admin_fee',
      optionsType: [
        {
          text: 'Biaya Admin',
          value: 'admin_fee',
        },
        {
          text: 'Sharing Fee',
          value: 'sharing_fee',
        },
      ],
      filterYear: moment().format('YYYY-MM'),
      START_YEAR_NOW,
      moment,
    }
  },
  mounted() {
    this.getChartInvoice()
    this.getTotalInvoice()
  },
  methods: {
    async getChartInvoice() {
      this.loading = true
      const params = `type=${this.filterIsPaid}&fee_type=${this.filterType || 'admin_fee'}&year=${YEAR(this.filterYear)}`
      const url = `/v1/dashboard/invoice?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.series = [
            {
              name: 'Customer Service',
              data: data.filter(el => el.skill === 'Customer Service').map(el => el.total),
              color: '#E31A1A',
            },
            {
              name: 'Live Streamer',
              data: data.filter(el => el.skill === 'Live Streamer').map(el => el.total),
              color: '#FBBC05',
            },
            {
              name: 'Advertiser',
              data: data.filter(el => el.skill === 'Advertiser').map(el => el.total),
              color: '#34A770',
            },
            {
              name: 'Admin Marketplace',
              data: data.filter(el => el.skill === 'Admin Marketplace').map(el => el.total),
              color: '#08A0F7',
            },
          ]
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getTotalInvoice() {
      this.loading = true
      const params = `date=${YEAR_MONTH_DAY(moment(this.filterMonth).date(1))}`
      const url = `/v1/dashboard/total-invoice?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.dataTotal = data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
  },
}
</script>

<style>
.v-select.vs--single .vs__selected {
  color: #333333;
}
div.date-popover.visible {
  top: 30px;
  left: 25px !important;
}
</style>
